.Tooltip {
  position: absolute;
  display: block;
  visibility: visible;
  z-index: 10;
}

.Tooltip-overlay {
  width: 100%;
  padding: 0;
  background-color: white;
  color: black;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.15;
  box-shadow: none;
}

.Tooltip-overlay p {
  margin-bottom: 0;
}

.Tooltip-heading {
  font-size: 16px;
}

