@font-face {
  font-family: 'Euclid Circular B';
  font-weight: 400;
  src: url('./assets/fonts/euclidcircularb-regular.woff2') format('woff2'),
  url('./assets/fonts/euclidcircularb-regular.woff') format('woff');
  font-display: fallback;
}

@font-face {
  font-family: 'Euclid Circular B';
  font-weight: 600;
  src: url('./assets/fonts/euclidcircularb-semibold.woff2') format('woff2'),
  url('./assets/fonts/euclidcircularb-semibold.woff') format('woff');
  font-display: fallback;
}

@font-face {
  font-family: 'Euclid Circular B';
  font-weight: 700;
  src: url('./assets/fonts/euclidcircularb-bold.woff2') format('woff2'),
  url('./assets/fonts/euclidcircularb-bold.woff') format('woff');
  font-display: fallback;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: url('./assets/fonts/Montserrat-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: fallback;
  src: url('./assets/fonts/Montserrat-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: fallback;
  src: url('./assets/fonts/Montserrat-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: url('./assets/fonts/Montserrat-Bold.woff') format('woff');
}
